import React from 'react'
import { Container } from '@mui/material'

import Main from './Main'
import Footer from './Footer'

type UnauthenticatedLayoutProps = {
  children: JSX.Element
}

const UnauthenticatedLayout: React.FC<UnauthenticatedLayoutProps> = ({
  children
}: UnauthenticatedLayoutProps) => {
  return (
    <Container
      maxWidth='xs'
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignContent: 'center'
      }}
    >
      <Main>{children}</Main>
      <Footer />
    </Container>
  )
}

export default UnauthenticatedLayout
